import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
// import serializeMiddleware from 'store/customMiddleware';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import authReducer  from "context/auth/authSlice";
import pageReducer  from "context/pages/pageSlice";
import notificationsReducer  from "context/notification/notificationsApiSlice";
import storage from 'redux-persist/lib/storage';


import { apiSlice } from "api/apiSlice";

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
    whitelist: ['page','notifications']
  };

const rootReducer = combineReducers({
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    page: pageReducer,
    notifications : notificationsReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
 /* eslint-disable */
export const store = configureStore({
    reducer : persistedReducer,
    middleware : getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                // Ignore these field paths in all actions
                ignoredActionPaths: ['meta.arg', 'payload.timestamp','meta.baseQueryMeta.request','meta.baseQueryMeta.response'],
                // Ignore these paths in the state
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER,'api/executeQuery/fulfilled'],
              },
        }).concat([
            apiSlice.middleware,
            // serializeMiddleware
        ]),
    
    devTools : true
    
});
export const persistor = persistStore(store);
setupListeners(store.dispatch)