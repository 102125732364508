import {
  createAction,
  createSlice,
  createEntityAdapter,
  createSelector,
  isAnyOf,
} from '@reduxjs/toolkit';

import { apiSlice } from 'api/apiSlice';

// Action to handle received notifications
const notificationsReceived = createAction(
  'notifications/notificationsReceived'
);

// Create entity adapter and disable sorting
const notificationAdapter = createEntityAdapter({
  sortComparer: null // Disable automatic sorting by id
});

const initialState = notificationAdapter.getInitialState({
  notificationsDetails: {
    page: 0,
    filters: {},
    notificationsPerpage: 20,
    total_results: 0,
  },
});

// Inject the endpoints into your API slice
export const notificationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: params => ({url : '/notifications', params}),
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }
      ) {
        try {
          // Wait for the cache data to load
          await cacheDataLoaded;

          // Retrieve the message from the cache data
          const message = cacheDataLoaded;

          // Update the cache with the new data (no sorting applied)
          updateCachedData((draft) => {
            draft.upsertMany(message.payload);
          });

          // Dispatch the received notifications
          dispatch(notificationsReceived(message.payload));
        } catch {
          // Handle cache loading errors (if any)
        }

        // Wait for the cache to be removed (cleanup logic)
        await cacheEntryRemoved;
      },
      validateStatus: (response, result) =>
        response.status === 200 && !result.isError,
      transformResponse: (responseData) => {
        const loadedNotification = responseData.notifications;
         /* eslint-disable */
        const updatedState = {
          ...initialState,
          notificationsDetails: {
            ...initialState.notificationsDetails,
            page: responseData.page,
            filters: responseData.filters,
            notificationsPerpage: responseData.notificationsPerpage,
            total_results: responseData.total_results,
          }
        };
        // Load notifications into the state without sorting
        return notificationAdapter.upsertMany(updatedState, loadedNotification);
      },
      providesTags: (result, error, id) =>
        result?.ids
          ? [
              { type: 'Notification', id: 'LIST' },
              ...result.ids.map((id) => ({ type: 'Notification', id })),
            ]
          : [{ type: 'Notification', id: 'LIST' }],
    }),
    getUserNotification: builder.query({
      query: (id) => ({ url: `userNotification/${id}` }),
      validateStatus: (response, result) =>
        response.status === 200 && !result.isError,
      transformResponse: (responseData) => {
        const loadedNotification = responseData;
        return loadedNotification;
      },
      providesTags: (result, error, id) => [{ type: 'Notification', id }],
    }),
    addNewNotification: builder.mutation({
      query: (initialNotificationData) => ({
        url: '/notification',
        method: 'POST',
        body: {
          ...initialNotificationData,
        },
      }),
      invalidatesTags: [{ type: 'Notification', id: 'LIST' }],
    }),
    updateNotification: builder.mutation({
      query: (initialNotificationData) => ({
        url: '/notification',
        method: 'PATCH',
        body: {
          ...initialNotificationData,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Notification', id: arg.id },
      ],
    }),
    updateNotificationsAsRead: builder.mutation({
      query: (notificationIds) => ({
        url: `/notifications/mark-as-read`,
        method: 'PATCH',
        body: { ids: notificationIds }, // Send notification IDs to mark as read
      }),
      invalidatesTags: [{ type: 'Notification', id: 'LIST' }],
    }),
    deleteNotification: builder.mutation({
      query: ({ id }) => ({
        url: `/notification`,
        method: 'DELETE',
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Notification', id: arg.id },
      ],
    }),
    pushNotificationsSubscription  : builder.mutation({
      query: (initialNotificationData) => ({
        url: '/push-notification',
        method: 'POST',
        body: {
          ...initialNotificationData,
        },
      }),
      invalidatesTags: [{ type: 'Notification', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useGetUserNotificationQuery,
  useAddNewNotificationMutation,
  useUpdateNotificationMutation,
  useUpdateNotificationsAsReadMutation,
  useDeleteNotificationMutation,
  usePushNotificationsSubscriptionMutation,
} = notificationsApiSlice;

const emptyNotifications = [];

// Select the query result for notifications
export const selectNotificationsResult = notificationsApiSlice.endpoints.getNotifications.select();

// Selector to extract the notifications data
const selectNotificationsData = createSelector(
  selectNotificationsResult,
  (notificationsResult) => notificationsResult.data?.notifications ?? emptyNotifications
);

// Matcher to track when notifications are received
const matchNotificationsReceived = isAnyOf(
  notificationsReceived,
  notificationsApiSlice.endpoints.getNotifications.matchFulfilled
);

/* **************************************** THE NOTIFICATIONS SLICE ************************ */

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: notificationAdapter.getInitialState(),
  reducers: {
    allNotificationsRead(state, action) {
      const arrayNotif = action.payload.map((notification) => ({
        ...notification,
        seen : true
      }));
      notificationAdapter.upsertMany(state, arrayNotif)
    },
    allNewNotificationsSeen(state, action) {
      const arrayNotif = action.payload.map((notification) => ({
        ...notification,
        isNew : false
      }));
      notificationAdapter.upsertMany(state, arrayNotif)
    },
  },
  extraReducers(builder) {
    builder.addMatcher(matchNotificationsReceived, (state, action) => {
      // Add client-side metadata for tracking new notifications
      const arrayNotif = action.payload ? Object.values(action.payload.entities) : [];
      const notificationsMetadata = arrayNotif?.map((notification) => ({
        ...notification,
        seen: notification.seen === 1 ? true : false
      }));
      notificationAdapter.upsertMany(state, notificationsMetadata);
    });
  },
});
// export const selectAllTheNotifications    = state => Object.values(state.notifications.entities);

export const selectAllTheNotifications = createSelector(
  (state) => state.notifications,
  (notifications) => notifications
);

export const { allNotificationsRead, allNewNotificationsSeen } = notificationsSlice.actions;
export default notificationsSlice.reducer;

export const {
  selectAll:  selectAllNotifications,
  selectById: selectNotificationById,
  selectIds:  selectNotificationsIds,
} = notificationAdapter.getSelectors((state) =>
  selectNotificationsData(state) ?? initialState
);
