import GifLoader from "assets/images/denShop.gif";

const ScreenLoader = () => {
  return (
    <section
      style={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: "#000",
        flexDirection: "column",
        overflow: "hidden"
      }}
    >
      <figure style={{ margin: 0 }}>
        <img src={GifLoader} alt="gif loading" />
        <figcaption style={{ textAlign: 'center' }}>
          <h3 className="typewriter">Loading...</h3>
        </figcaption>
      </figure>
    </section>
  );
};

export default ScreenLoader;
